<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const processIsUnderway = computed(() => store.state.common.processIsUnderway)

const closeProcess = () => {
	store.commit('common/setProcess', {
		status: 'hidden',
		title: null
	})
}

watch(processIsUnderway, (process) => {
    if (process.status === 'display' || process.status === 'error') {
		setTimeout(() => {
			store.commit('common/setProcess', {
				status: 'hidden',
				title: null
			})
		}, 3000)
	}
}, { deep: true })
</script>

<template>
	<div class="process" :class="processIsUnderway.status">
		<div class="process__loading" v-show="processIsUnderway.status === 'loading'">
			<svg width="17rem" height="16rem" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15.5188 8C16.0607 8 16.5062 8.44093 16.4399 8.97875C16.2876 10.2143 15.8484 11.402 15.1518 12.4446C14.2727 13.7602 13.0233 14.7855 11.5615 15.391C10.0997 15.9965 8.49113 16.155 6.93928 15.8463C5.38743 15.5376 3.96197 14.7757 2.84315 13.6569C1.72433 12.538 0.9624 11.1126 0.653718 9.56072C0.345036 8.00887 0.503463 6.40034 1.10896 4.93853C1.71446 3.47672 2.73985 2.22729 4.05544 1.34824C5.09801 0.65162 6.28573 0.212393 7.52125 0.0600913C8.05907 -0.00620513 8.5 0.439289 8.5 0.981179C8.5 1.52307 8.05786 1.95423 7.52312 2.04191C6.67647 2.18073 5.86526 2.49907 5.14567 2.97988C4.15278 3.64331 3.37892 4.58626 2.92195 5.68949C2.46497 6.79273 2.34541 8.0067 2.57837 9.17789C2.81133 10.3491 3.38636 11.4249 4.23074 12.2693C5.07512 13.1136 6.15093 13.6887 7.32211 13.9216C8.4933 14.1546 9.70727 14.035 10.8105 13.5781C11.9137 13.1211 12.8567 12.3472 13.5201 11.3543C14.0009 10.6347 14.3193 9.82353 14.4581 8.97688C14.5458 8.44213 14.9769 8 15.5188 8Z" fill="white"/>
				<path opacity="0.2" d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM2.46236 8C2.46236 11.3345 5.1655 14.0376 8.5 14.0376C11.8345 14.0376 14.5376 11.3345 14.5376 8C14.5376 4.6655 11.8345 1.96236 8.5 1.96236C5.1655 1.96236 2.46236 4.6655 2.46236 8Z" fill="white"/>
			</svg>
		</div>
		<div class="process__title" v-if="processIsUnderway.title">{{ $t(`${processIsUnderway.title}`) }}</div>
		<div class="process__close" @click="closeProcess()">
			<svg width="11rem" height="10rem" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0.709802 0.209802C0.776139 0.143298 0.854944 0.0905335 0.941704 0.0545322C1.02846 0.0185308 1.12147 0 1.21541 0C1.30934 0 1.40235 0.0185308 1.48911 0.0545322C1.57587 0.0905335 1.65467 0.143298 1.72101 0.209802L5.50019 3.99041L9.27936 0.209802C9.34576 0.143405 9.42459 0.0907363 9.51134 0.0548025C9.59809 0.0188688 9.69107 0.000373895 9.78497 0.000373895C9.87887 0.000373895 9.97185 0.0188688 10.0586 0.0548025C10.1454 0.0907363 10.2242 0.143405 10.2906 0.209802C10.357 0.276199 10.4096 0.355023 10.4456 0.441775C10.4815 0.528527 10.5 0.621507 10.5 0.715406C10.5 0.809306 10.4815 0.902286 10.4456 0.989038C10.4096 1.07579 10.357 1.15461 10.2906 1.22101L6.50997 5.00019L10.2906 8.77936C10.357 8.84576 10.4096 8.92459 10.4456 9.01134C10.4815 9.09809 10.5 9.19107 10.5 9.28497C10.5 9.37887 10.4815 9.47185 10.4456 9.5586C10.4096 9.64535 10.357 9.72418 10.2906 9.79057C10.2242 9.85697 10.1454 9.90964 10.0586 9.94557C9.97185 9.98151 9.87887 10 9.78497 10C9.69107 10 9.59809 9.98151 9.51134 9.94557C9.42459 9.90964 9.34576 9.85697 9.27936 9.79057L5.50019 6.00997L1.72101 9.79057C1.65461 9.85697 1.57579 9.90964 1.48904 9.94557C1.40229 9.98151 1.30931 10 1.21541 10C1.12151 10 1.02853 9.98151 0.941775 9.94557C0.855023 9.90964 0.776199 9.85697 0.709802 9.79057C0.643405 9.72418 0.590736 9.64535 0.554803 9.5586C0.518869 9.47185 0.500374 9.37887 0.500374 9.28497C0.500374 9.19107 0.518869 9.09809 0.554803 9.01134C0.590736 8.92459 0.643405 8.84576 0.709802 8.77936L4.49041 5.00019L0.709802 1.22101C0.643298 1.15467 0.590534 1.07587 0.554532 0.989109C0.518531 0.902349 0.5 0.809339 0.5 0.715406C0.5 0.621474 0.518531 0.528463 0.554532 0.441704C0.590534 0.354944 0.643298 0.276139 0.709802 0.209802Z" fill="white"/>
			</svg>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.process {
	position: fixed;
	bottom: 20rem;
	left: 50%;
	transform: translateX(-50%);
	height: 28rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6rem 12rem;
	border-radius: 20rem;
	font-family: 'PTSans', sans-serif;
	box-shadow: 0px 6px 15px rgba(55, 73, 87, 0.3);
	transition: opacity 0.3s ease-in-out;
	opacity: 1;
	visibility: visible;
	z-index: 5;
	&.hidden {
		opacity: 0;
		visibility: hidden;
		max-height: 0;
		z-index: -1;
	}
	&.loading {
		background: linear-gradient(90deg, #7185AC 0%, var(--text-grey-color) 100%);
	}
	&.display {
		background: var(--text-color);
	}
	&.error {
		background: linear-gradient(268.15deg, #DE323A 3.13%, #E95541 96.87%);
	}
	&__loading {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 16rem;
		height: 16rem;
		margin-right: 10rem;
		animation: rotate 1.5s linear infinite;
	}
	&__title {
		color: white;
		font-size: 12rem;
		margin-right: 10rem;
	}
	&__close {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 10rem;
		height: 10rem;
		cursor: pointer;
	}
}
@keyframes rotate{
  	to {
		transform: rotate(360deg);
	}
}
</style>